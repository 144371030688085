import React, {useState} from 'react'
import {Container} from 'react-bootstrap'
import './LanguageController.scss'
import {connect} from "react-redux";
import {setLocaleWithFallback} from "../../Redux/actions/app";
import {i18nReducer} from "react-redux-i18n";

interface LanguageControllerProps {
    children?: React.ReactNode
    setLocaleWithFallback: (locale: string) => void
    currentLocale: string

}

const LanguageController = (props: LanguageControllerProps) => {
    const [currentLocale, setCurrentLocale] = useState(props.currentLocale)

    const changeLanguage = () => {

        if(currentLocale === undefined){
            setCurrentLocale("ru")
            props.setLocaleWithFallback("ru")
        }
        if(currentLocale === "ru"){
            setCurrentLocale("en")
            props.setLocaleWithFallback("en")
        }
        if(currentLocale === "en"){
            setCurrentLocale("ru")
            props.setLocaleWithFallback("ru")
        }
    }
    return (
        <Container onClick={() => changeLanguage()} fluid className="LanguageController">
            {props.children}
            {currentLocale}
        </Container>

    )
}

const mapDispatchToProps = {setLocaleWithFallback};
export default connect(null, mapDispatchToProps)(LanguageController);