import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import {loadTranslations, setLocale, syncTranslationWithStore} from "react-redux-i18n"
import translations from "../locales/translations.json"

export type RootState = ReturnType<typeof rootReducer>

// let store = createStore(rootReducer, applyMiddleware(thunk))
let store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("ru"));

export default store