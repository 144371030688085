import React, {ReactNode} from 'react'
import {Container, Tab, Tabs} from 'react-bootstrap'
import './Catalog.scss'
import Card from "../../SharedComponents/Card/Card";
import BackgroundHeader from "../../SharedComponents/BackgroundHeader/BackgroundHeader";
import {Translate} from "react-redux-i18n";
import _products from "../../Data/products.json"
import ProductData from "../../Model/ProductData";
import {setModalObjectId, setModalWindowProduct} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {ModalState} from "../../Redux/interfaces/interfaces";
import ScrollToTop from "../../SharedComponents/ScrollToTop/ScrollToTop";

interface CatalogProps {
    setModalObjectId: (productId: number) => void
    setModalWindowProduct: (isVisible: boolean) => void
    modal: ModalState
}


const Catalog = (props: CatalogProps) => {
    ScrollToTop()
    const products = _products as ProductData[];

    const showModalWindow = (productId: number) => {
        props.setModalObjectId(productId)
        props.setModalWindowProduct(true)
    }

    const cardsBeef: ReactNode[] =
        products
            .filter(product => product.type.toLowerCase() === 'говядина')
            .map(product =>
                <Card
                    onClick={() => showModalWindow(product.id)}
                    img={product.img}
                    title={product.name}
                    meatType={product.type}
                    weight={product.weight}/>
            )


    const cardsMutton: ReactNode[] = products
        .filter(product => product.type.toLowerCase() === 'баранина')
        .map(product =>
            <Card
                onClick={() => showModalWindow(product.id)}
                img={product.img}
                title={product.name}
                meatType={product.type}
                weight={product.weight}/>
        )

    const cardsAll = cardsMutton.concat(cardsBeef)

    return (
        <div className="Catalog p-0">
            <div className={"BackgroundHeader__title container"}><Translate value={"menu.catalog"}/></div>
            <BackgroundHeader img={"/img/catalog_bg.png"}/>
            <Container>
                <Tabs
                    defaultActiveKey="all"
                    className="Catalog__tabs "
                >
                    <Tab eventKey="all" title={<Translate value="common.whole_range"/>}>
                        <Container className={"Catalog__all"}>
                            {cardsAll.map((card, index) => {
                                return (
                                    <div key={index}>
                                        {card}
                                    </div>
                                )
                            })}
                        </Container>
                    </Tab>
                    <Tab eventKey="beef" title={<Translate value="common.beef"/>}>
                        <Container className={"Catalog__beef"}>
                            {cardsBeef.map((card, index) => {
                                return (
                                    <div key={index}>
                                        {card}
                                    </div>
                                )
                            })}
                        </Container>
                    </Tab>
                    <Tab eventKey="mutton" title={<Translate value="common.mutton"/>}>
                        <Container className={"Catalog__mutton"}>
                            {cardsMutton.map((card, index) => {
                                return (
                                    <div key={index}>
                                        {card}
                                    </div>

                                )
                            })}
                        </Container>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

const mapDispatchToProps = {
    setModalWindowProduct: setModalWindowProduct,
    setModalObjectId: setModalObjectId
}
const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
