import { combineReducers } from 'redux'
import app from './app'
import toast from './toast'
import modal from './modal'
import frontApp from './frontApp'
import { i18nReducer } from "react-redux-i18n";
import {i18nState} from "redux-i18n"

export default combineReducers({
  i18nState,
  i18n: i18nReducer,
  app,
  frontApp,
  toast,
  modal,
})