import React from 'react'
import './BackgroundHeader.scss'

interface BackgroundHeaderProps {
    img: string
}

const BackgroundHeader = (props: BackgroundHeaderProps) => {
    return (
        <div className="BackgroundHeader"
             style={{
                 background:`url(${props.img})`,
                 backgroundRepeat: 'no-repeat',
                 backgroundSize: 'cover',
                 backgroundPosition: 'center'
             }}>
        </div>
    )
}
export default BackgroundHeader
