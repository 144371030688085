import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import './SendEmail.scss'
import Button from '../Button/Button'
import {showRequestModal} from '../../Redux/actions/modal'
import {connect} from 'react-redux'
import {Translate} from 'react-redux-i18n'

interface SendEmailProps {
    showRequestModal: () => void
    isPhone: boolean
}


const SendEmail = (props: SendEmailProps) => {
    return (
        <Container className={'SendEmail'}>
            <Row>
                <Col xs={12} className="d-block d-lg-none">
                    <div className={'SendEmail__text m-auto'}>Если у вас возникли вопросы, свяжитесь с нами</div>
                </Col>
                <Col xs={12} className="d-block d-lg-none">
                    <Button onClick={props.showRequestModal}>
                        <Translate value={'common.send_email'}/>
                    </Button>
                </Col>
                {props.isPhone
                    ? (<Col className={'SendEmail__email'} lg={5}>
                        <span>телефон</span>
                        <div>
                            <a href={"tel:+79270726350"}>+7 927 072 63 50</a>
                        </div>
                    </Col>)
                    : (<Col className={'SendEmail__email'} lg={5}>
                        <span>почта</span>
                        <div>
                            <a href={'mailto:mysko@mysko.ru'}>mysko@mysko.ru</a>
                        </div>
                    </Col>)}

                <Col className={'SendEmail__button d-none d-lg-block'} lg={7}>
                    <Container fluid className="p-0">
                        <Row className="m-0">
                            <Col lg={6}>
                                <div className={'SendEmail__text m-auto'}>Если у вас возникли вопросы, свяжитесь с
                                    нами
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Button onClick={props.showRequestModal}>
                                    <Translate value={'common.send_email'}/>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

const mapDispatchToProps = {
    showRequestModal: showRequestModal,
}

export default connect(null, mapDispatchToProps)(SendEmail)
