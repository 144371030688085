import React from 'react'
import './About.scss'
import {Container, Row, Col} from 'react-bootstrap'

const About = () => {
    return (
        <>
            <Container fluid className={'About d-none d-sm-block'}>
                <div className={'BlockHeader__right'}>
                    <h1>
                        <span>О нас</span>
                    </h1>
                </div>

                <Row className="About__Row1">
                    <Col
                        className="About__ColLeft About__img About__imgLeft"
                        style={{
                            background: `url(/img/about1.jpg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    />
                    <Col className="About__ColRight About__text d-flex align-items-center">
                        <div className="About__right">
                            <h3>Высокое качество</h3>
                            <p>
                                Мы стараемся полностью удовлетворять запросам покупателей, которые хотят наслаждаться
                                мясом высокого
                                качества. Поэтому мы прислушиваемся к желаниям клиентов и исполняем их, предлагая самую
                                свежую продукцию
                                в вакуумной упаковке для всей семьи.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="About__Row1">
                    <Col className="About__ColLeft About__text d-flex justify-content-end align-items-center">
                        <div className="About__left">
                            <h3>Экологически чистое мясо</h3>
                            <p>
                                Наши животные выращиваются на лугах и зеленых пастбищах, которые являются самым ценным
                                природным
                                источником пищи для рогатого скота.
                            </p>
                            <p>
                                Главный критерий для нас — сохранение экологичной среды и чистого воздуха, что дает нам
                                несомненное
                                преимущество перед другими заводами.
                            </p>
                        </div>
                    </Col>
                    <Col
                        className="About__ColRight About__img About__imgRight"
                        style={{
                            background: `url(/img/about_2.jpg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    />
                </Row>
            </Container>

            <Container fluid className={'About d-block d-sm-none'}>
                <div className={'BlockHeader__right'}>
                    <h1>
                        <span>О нас</span>
                    </h1>
                </div>
                <Row className="About__Row">
                    <Col xs={12} className="About__ColRight About__Col">
                        <div className="">
                            <h3>Высокое качество</h3>
                            <p>
                                Мы стараемся полностью удовлетворять запросам покупателей, которые хотят наслаждаться
                                мясом высокого
                                качества. Поэтому мы прислушиваемся к желаниям клиентов и исполняем их, предлагая самую
                                свежую продукцию
                                в вакуумной упаковке для всей семьи.
                            </p>
                        </div>
                    </Col>
                    <Col
                        xs={12}
                        className="About__ColImg About__Col"
                        style={{
                            background: `url(/img/about_mob_1.jpg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    />
                </Row>
                <Row className="About__Row">
                    <Col xs={12} className="About__ColRight About__Col">
                        <div className="">
                            <h3>Экологически чистое мясо</h3>
                            <p>
                                Наши животные выращиваются на лугах и зеленых пастбищах, которые являются самым ценным
                                природным
                                источником пищи для рогатого скота.
                            </p>
                            <p>
                                Главный критерий для нас — сохранение экологичной среды и чистого воздуха, что дает нам
                                несомненное
                                преимущество перед другими заводами.
                            </p>
                        </div>
                    </Col>
                    <Col
                        xs={12}
                        className="About__ColImg About__Col"
                        style={{
                            background: `url(/img/about_mob_2.jpg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    />
                </Row>
            </Container>
        </>
    )
}

export default About
