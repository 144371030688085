import React from 'react'
import './Recipes.scss'
import {Col, Container, Row} from 'react-bootstrap'
import RecipeCard from './RecipeCard/RecipeCard'
import {connect} from 'react-redux'
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper'

import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import {Translate} from "react-redux-i18n";
import {setModalObjectId, setModalWindowRecipe} from "../../../Redux/actions/modal";
import {ModalState} from "../../../Redux/interfaces/interfaces";
import {RootState} from "../../../Redux";

import _recipes from "../../../Data/recipes.json"
import RecipeData from "../../../Model/RecipeData";
import {Link} from "react-router-dom";

interface RecipesProps {
    modal: ModalState
    setModalWindowRecipe: (isActive: boolean) => void
    setModalObjectId: (recipeId: number) => void
}

const Recipes = (props: RecipesProps) => {

    const recipes = _recipes as RecipeData[];

    const showModalWindow = (recipeId: number) => {
        props.setModalObjectId(recipeId)
        props.setModalWindowRecipe(true)
    }

    return (
        <Container fluid className={'Recipes'}>
            <div className={'BlockHeader__right'}>
                <h1>
                    <span><Translate value="menu.recipes"/></span>
                </h1>
            </div>

            <Container className="Recipes__cont d-none d-sm-block">
                <Row className="Recipes__Row">
                    <Col onClick={() => showModalWindow(recipes[0].id)} md={5} lg={6}
                         className="Recipes__ColLeft Recipes__card">
                        <RecipeCard isModal={true} recipe={recipes[0]}/>
                    </Col>
                    <Col md={7} lg={6} className="Recipes__ColRight">
                        <Container fluid className="Recipes__array p-0">
                            <Row className="m-0">
                                {recipes.map((recipe) => {
                                    if (recipe.id > 1 && recipe.id < 6) {
                                        return (
                                            <Col onClick={() => showModalWindow(recipe.id)} key={recipe.id} xs={6}
                                                 className="Recipes__card">
                                                <RecipeCard recipe={recipe}/>
                                            </Col>
                                        )
                                    }
                                })}

                            </Row>
                        </Container>
                    </Col>


                </Row>
                <div className={"Recipes__more"}>
                    <div><Link to="/recipes"><Translate value="common.more_recipes"/></Link></div>
                </div>
            </Container>

            <Container fluid className="d-block d-sm-none Recipes__Slides">
                <div className="swiper-controls">
                    <div
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + 'icons/arrow_left.svg'})`,
                        }}
                        className="swiper-prev swiper-btn"
                        id="Prev"
                    />
                    <div
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + 'icons/arrow_right.svg'})`,
                        }}
                        className="swiper-next swiper-btn"
                        id="Next"
                    />
                </div>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    loop={true}
                    navigation={{
                        nextEl: `#Next`,
                        prevEl: `#Prev`,
                    }}
                    slidesPerView={1}
                    spaceBetween={10}
                >
                    {recipes.map((recipe) => {
                        return (
                            <SwiperSlide onClick={() => showModalWindow(recipe.id)}
                                         key={recipe.id}>
                                <RecipeCard recipe={recipe}/>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Container>
        </Container>
    )
}

const mapDispatchToProps = {
    setModalWindowRecipe: setModalWindowRecipe,
    setModalObjectId: setModalObjectId
}

const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipes)
