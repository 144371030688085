import React, {SyntheticEvent, useState} from 'react'
import {Container, Tab, Tabs} from 'react-bootstrap'
import './Partners.scss'
import Button from "../../SharedComponents/Button/Button";
import BackgroundHeader from "../../SharedComponents/BackgroundHeader/BackgroundHeader";
import {showRequestModal} from "../../Redux/actions/modal";
import {connect} from "react-redux";
import ScrollToTop from "../../SharedComponents/ScrollToTop/ScrollToTop";
import {Translate} from "react-redux-i18n";
import FAQ from "../../SharedComponents/FAQ/FAQ"

interface PartnersProps {
    showRequestModal: () => void
}

const Partners = (props: PartnersProps) => {

    const [isBg1, setBg1] = useState(true)
    const [isBg2, setBg2] = useState(false)
    const [isBg3, setBg3] = useState(false)


    const updateBgImage = (value: string | null) => {
        // setBgImage(bgImage)
        console.log(value)
        switch (value) {
            case 'retail':
                setBg1(true)
                setBg2(false)
                setBg3(false)
                break
            case 'wholesale':
                setBg1(false)
                setBg2(true)
                setBg3(false)
                break
            case 'horeca':
                setBg1(false)
                setBg2(false)
                setBg3(true)
                break
        }
    }

    ScrollToTop()
    return (
        <div className="Partners">
            <div className={"BackgroundHeader__title container"}><Translate value={"menu.partners"}/></div>
            {isBg1 ? <BackgroundHeader img={"/img/partners1.jpg"}/> : null}
            {isBg2 ? <BackgroundHeader img={"/img/partners2.jpg"}/> : null}
            {isBg3 ? <BackgroundHeader img={"/img/partners3.jpg"}/> : null}
            <Container>

                <Tabs
                    defaultActiveKey="retail"
                    className="Partners__tabs "
                    onSelect={value => updateBgImage(value)}
                >
                    <Tab eventKey="retail" title="Retail">
                        <div className={"Partners__text-block"}>
                            <h2>О нас</h2>
                            <p>Хорошие и доверительные отношения с клиентами — наша первостепенная задача!
                                Мы тщательно следим за качеством продукта и сроками поставки.
                                Через наших дистрибьютеров работаем с ресторанами,
                                кафе, гостиницами и барами по всей России.
                                Наши менеджеры всегда подскажут оптимальные варианты сотрудничества:
                                прямые поставки или поставки от наших дистрибьютеров.</p>
                        </div>
                        <div className={"Partners__cards mt-5 "}>
                            <div className={"Partners__card"}>
                                <div className={"Partners__card-icon"}><img src="icons/hands_icon.svg" alt="quality"/>
                                </div>
                                <div className={"card-title"}>Надежный партнер</div>
                                <p>Выполняем все обязательства, взятые на себя. Доставляем столько продукции, сколько вы
                                    заказали.</p>
                            </div>
                            <div className={"Partners__card"}>
                                <div className={"Partners__card-icon"}><img src="icons/time_icon.svg" alt="quality"/>
                                </div>
                                <div className={"card-title"}>Поставки в срок</div>
                                <p>На предприятии работает до 100 человек, которые гарантируют стабильную работу
                                    производства</p>
                            </div>

                            <div className={"Partners__card"}>
                                <div className={"Partners__card-icon"}><img src="icons/prize_icon.svg" alt="quality"/>
                                </div>
                                <div className={"card-title"}>Отмечены наградами и дипломами</div>
                                <p>от АО «Российский экспортный центр», Metro Quality Aword и других</p>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="wholesale" title="Оптовые клиенты">
                        <div className={"Partners__text-block"}>
                            <h2>О нас</h2>
                            <p>Входим в десятку крупнейших экспортеров мяса среди производителей южной части России.
                                Ассортимент продукции выработан в соответствии с требованиями «халяль».
                                Работаем с ведущими ретейлерами РФ, такими как «Магнит», «Лента», «Метро».
                                Мы всегда рады работать с новыми клиентами — начать новые долгосрочные отношения!  </p>
                        </div>
                        <div className={"Partners__wholesale"}>
                            <div className={"Partners__wholesale-card"}>
                                <span>10 000</span>
                                <p>тонн мяса произвели
                                    с 2014 года</p>
                            </div>
                            <div className={"Partners__wholesale-card"}>
                                <span>3 000</span>
                                <p>тонн мяса экспортировали</p>
                            </div>
                            <div className={"Partners__wholesale-card"}>
                                <span>30</span>
                                <p>тонн в сутки —
                                    производственная
                                    мощность завода</p>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="horeca" title="HoReCa">
                        <div className={"Partners__text-block"}>
                            <h2>О нас</h2>
                            <p>Хорошие и доверительные отношения с клиентами — наша первостепенная задача!
                                Производим мясо баранины и говядины с 2014 года.
                                Входим в десятку крупнейших экспортеров мяса среди производителей южной части России.
                                Ассортимент продукции выработан в соответствии с требованиями халяль.
                                Наши менеджеры всегда подскажут оптимальные варианты сотрудничества:
                                прямые поставки или поставки от наших дистрибьютеров.
                                Главное для нас — привезти мясо вовремя и в срок.</p>
                        </div>
                    </Tab>
                </Tabs>

                <div className={"Partners__start"}>
                    <div>Как стать партнером?</div>
                    <p>Начать очень просто!</p>
                    <div className={"Partners__step"}>
                        <span>1</span>
                        <div>Подача заявки</div>
                    </div>

                    <div className={"Partners__step"}>
                        <span>2</span>
                        <div>Оформление документов</div>
                    </div>
                    <div className={"mt-3"}>
                        <Button onClick={props.showRequestModal}>Подать заявку</Button>
                    </div>

                </div>
                <div className={"Partners__why-we"}>
                    <h1>ПОЧЕМУ МЫ?</h1>
                    <div className={"Partners__cards"}>
                        <div className={"Partners__card"}>
                            <div className={"Partners__card-icon"}><img src="icons/minorder_icon.svg" alt="quality"/>
                            </div>
                            <div className={"card-text"}>Индивидуальный подход
                            </div>
                        </div>
                        <div className={"Partners__card"}>
                            <div className={"Partners__card-icon"}><img src="icons/quality_icon.svg" alt="quality"/>
                            </div>
                            <div className={"card-text"}>Высокое качество продукта</div>
                        </div>
                        <div className={"Partners__card"}>
                            <div className={"Partners__card-icon"}><img src="icons/truck_icon.svg" alt="quality"/></div>
                            <div className={"card-text"}>Доставка
                                по всей России
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"d-flex justify-content-center"}>
                    <Button onClick={props.showRequestModal}>Стать партнером</Button>
                </div>
                <FAQ/>

            </Container>
        </div>

    )

}

const mapDispatchToProps = {
    showRequestModal: showRequestModal
}

export default connect(null, mapDispatchToProps)(Partners)
