import React from 'react'
import './NewsCard.scss'
import _news from "../../Data/news.json"
import NewsData from "../../Model/NewsData";
import {Container} from "react-bootstrap";
import {
    hideModalProduct,
    hideModalRecipe,
    hideModalSearch,
    hideRequestModal, setModalObjectId,
    setModalWindow, setModalWindowRecipe
} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";

interface NewsCardProps {
    newsId: number
}

const NewsCard = (props: NewsCardProps) => {

    const newsList = _news as NewsData[];

    const news = newsList.find(n => n.id === props.newsId)

    return (
        <div className="NewsCard">
            <div className={"NewsCard__image"}>
                <img className={"img-fluid"} src={`${news?.img_modal}`} alt={news?.title}/>
            </div>

            <div className={"NewsCard__body"}>
                <h3>{news?.title}</h3>
                <Container dangerouslySetInnerHTML={{__html: news?.body || ""}}/>
            </div>

        </div>
    )
}
const mapDispatchToProps = {
    setModalWindow: setModalWindow,
    hideRequestModal: hideRequestModal,
    hideModalRecipe: hideModalRecipe,
    hideModalProduct: hideModalProduct,
    hideModalSearch: hideModalSearch,
    setModalObjectId: setModalObjectId,
    setModalWindowRecipe: setModalWindowRecipe
}
const mapStateToProps = (state: RootState) => {
    const newsId = state.modal.objectId
    return {
        newsId,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewsCard)


