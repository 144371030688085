export const SET_APP_ERROR = '@APP/SET_APP_ERROR'
export const SET_APP_LOADING = '@APP/SET_APP_LOADING'
export const SET_APP_TARIFF = '@APP/SET_APP_TARIFF'
export const SET_APP_MARKETUSER = '@APP/SET_APP_MARKETUSER'
export const SET_APP_PRODUCTS = '@APP/SET_APP_PRODUCTS'
export const SET_APP_PRODUCT_CATEGORIES = '@APP/SET_APP_PRODUCT_CATEGORIES'
export const SET_APP_PAGINATION = '@APP/SET_APP_PAGINATION'

export const SET_ERROR_TOAST = '@TOAST/SET_ERROR_TOAST'
export const SET_SUCCESS_TOAST = '@TOAST/SET_SUCCESS_TOAST'
export const HIDE_TOAST = '@TOAST/HIDE_TOAST'

export const SHOW_REQUESTFORM_MODAL = '@MODAL/SHOW_REQUESTFORM_MODAL'
export const HIDE_REQUESTFORM_MODAL = '@MODAL/HIDE_REQUESTFORM_MODAL'
export const HIDE_ALL_MODAL = '@MODAL/HIDE_ALL_MODAL'
export const HIDE_MODAL_WINDOW_PRODUCT = '@MODAL/HIDE_MODAL_WINDOW_PRODUCT'
export const HIDE_MODAL_WINDOW_RECIPE = '@MODAL/HIDE_MODAL_WINDOW_RECIPE'
export const HIDE_MODAL_WINDOW_SEARCH = '@MODAL/HIDE_MODAL_WINDOW_SEARCH'
export const SET_MOBILE_MENU = '@MODAL/SET_MOBILE_MENU'
export const SET_MODAL_WINDOW = '@MODAL/SET_MODAL_WINDOW'
export const SET_MODAL_WINDOW_PRODUCT = '@MODAL/SET_MODAL_WINDOW_PRODUCT'
export const SET_MODAL_WINDOW_RECIPE = '@MODAL/SET_MODAL_WINDOW_RECIPE'
export const SET_MODAL_WINDOW_SEARCH = '@MODAL/SET_MODAL_WINDOW_SEARCH'
export const SET_MODAL_OBJECT_ID = '@MODAL/SET_MODAL_OBJECT_ID'

export const SET_FRONTAPP_AUTH = '@FRONTAPP/SET_FRONTAPP_AUTH'
export const SET_FRONTAPP_MESSAGE = '@FRONTAPP/SET_FRONTAPP_MESSAGE'
export const SET_FRONTAPP_LOADING = '@FRONTAPP/SET_FRONTAPP_LOADING'