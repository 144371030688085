import React, {useEffect} from 'react'
import {Container} from 'react-bootstrap'
import './ModalWindow.scss'
import {
    hideRequestModal,
    setModalWindow,
    hideModalProduct,
    hideModalRecipe,
    hideModalSearch
} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {ModalState} from "../../Redux/interfaces/interfaces";


interface ModalWindowProps {
    modal: ModalState
    children?: React.ReactNode
    setModalWindow: (isActive: boolean) => void
    hideRequestModal: () => void
    hideModalProduct: () => void
    hideModalRecipe: () => void
    hideModalSearch: () => void

}

const ModalWindow = (props: ModalWindowProps) => {
    useEffect(() => {
        bodyBlock()
    }, [])

    const bodyBlock = (): void => {
        document.querySelector('body')?.classList.add('kdsmodal_open')
    }

    const bodyUnBlock = (): void => {
        document.querySelector('body')?.classList.remove('kdsmodal_open')
    }

    const closeButtonHandler = (): void => {
        bodyUnBlock()
        if (props.modal.modalWindow.isActive)
            props.setModalWindow(false)
        if (props.modal.modalRequestForm.isActive)
            props.hideRequestModal()
        if (props.modal.modalWindowProduct.isActive)
            props.hideModalProduct()
        if (props.modal.modalWindowRecipe.isActive)
            props.hideModalRecipe()
        if (props.modal.modalWindowSearch.isActive)
            props.hideModalSearch()
    }

    const addClass = () => {
        if (props.modal.modalRequestForm.isActive)
            return "request"
        if (props.modal.modalWindowProduct.isActive)
            return "product"
        if (props.modal.modalWindowRecipe.isActive)
            return "recipe"
        if (props.modal.modalWindowSearch.isActive)
            return "search"
    }

    return (

        <div className={"ModalWindow"}>
            <div className={"ModalWindow__container " + addClass()}>
                <Container fluid className="ModalWindow__header p-0 d-flex justify-content-end">
                    <div className="ModalWindow__close">
                        <img onClick={() => closeButtonHandler()} src={"icons/close.svg"} alt="close"/>
                    </div>
                </Container>
                <Container fluid className="ModalWindow__body p-0 d-flex align-items-center justify-content-start">
                    {props.children}
                </Container>
            </div>
        </div>


    )
}

const mapDispatchToProps = {
    setModalWindow: setModalWindow,
    hideRequestModal: hideRequestModal,
    hideModalRecipe: hideModalRecipe,
    hideModalProduct: hideModalProduct,
    hideModalSearch: hideModalSearch,
}
const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow)
