import React from 'react'
import './Burger.scss'
import {Link} from "react-router-dom";
import {Translate} from "react-redux-i18n";
import SocialButtonsGroup from "../SocialButtonsGroup/SocialButtonsGroup";
import * as Scroll from "react-scroll";

interface BurgerProps {
    hideBurger: () => void
}

const Burger = (props: BurgerProps) => {
    const scrollToElement: (element: string) => void = (element: string) => {
        props.hideBurger()
        Scroll.scroller.scrollTo(element, {
            duration: 500,
            delay: 0,
            smooth: true,
        })
    }


    return (
        <div className="Burger">
            <div onClick={props.hideBurger} className="Burger__close">
                <img src={window.location.origin + "/icons/close.svg"} alt="close"/>
            </div>

            <div className="Burger__menu">
                <ul>
                    <li><Link onClick={props.hideBurger} to="/catalog"><Translate value="menu.catalog"/></Link></li>
                    <li><Link onClick={props.hideBurger} to="/partners"><Translate value="menu.partners"/></Link></li>
                    <li>
                        <Link onClick={() => scrollToElement('About')} to="/">
                            <Translate value="menu.about"/>
                        </Link>

                    </li>
                    <li><Link onClick={() => scrollToElement('News')} to="/">
                        <Translate value="menu.news"/>
                    </Link></li>
                    <li><Link to="/recipes">
                        <Translate value="menu.recipes"/>
                    </Link></li>
                    <li><Link onClick={props.hideBurger} to="/contacts"><Translate value="menu.contacts"/></Link></li>
                </ul>
            </div>
            <SocialButtonsGroup/>
        </div>
    )
}

export default Burger