import React from 'react'
import './Recipe.scss'
import _recipes from "../../Data/recipes.json"
import ScrollToTop from "../../SharedComponents/ScrollToTop/ScrollToTop";
import RecipeData from "../../Model/RecipeData";
import {useParams} from "react-router-dom";
import {Translate} from "react-redux-i18n";
import BackgroundHeader from "../../SharedComponents/BackgroundHeader/BackgroundHeader";
import {Container} from "react-bootstrap";

const Recipe = () => {
    ScrollToTop()
    let {recipeId} = useParams();
    const recipes = _recipes as RecipeData[];
    const recipe = recipes.find(rec => rec.link_name === recipeId)

    const getDifficulty = (difficulty: number | undefined) => {
        if (difficulty === 1)
            return "очень легко"
        if (difficulty === 2)
            return "легко"
        if (difficulty === 3)
            return "средняя"
        if (difficulty === 4)
            return "сложно"
        if (difficulty === 5)
            return "очень сложно"
    }

    return (
        <div className="Recipe p-0">
            <div className={"BackgroundHeader__title container"}><Translate value={"menu.recipes"}/></div>
            <BackgroundHeader img={"/img/recipe_bg.jpg"}/>
            {recipe ? (<Container className="RecipeModalContent">
                <div className={"RecipeModalContent__img"}>
                    <img className={"img-fluid"} src={`${recipe?.img}`} alt={recipe?.name}/>
                </div>
                <div className={"RecipeModalContent__info"}>
                    <h2>{recipe?.name}</h2>
                    <div className={"RecipeModalContent__param"}>
                        <div className={"RecipeModalContent__hint"}>ингредиенты</div>
                        <ul>
                            {recipe?.ingredients.map(ingredient => <li>{ingredient}</li>)}
                        </ul>
                    </div>
                    <div className={"RecipeModalContent__param"}>
                        <div className={"RecipeModalContent__hint"}>время приготовления</div>
                        <div>{recipe?.cooking_time}</div>
                    </div>
                    <div className={"RecipeModalContent__param"}>
                        <div className={"RecipeModalContent__hint"}>порции</div>
                        <div>{recipe?.servings}</div>
                    </div>
                    <div className={"RecipeModalContent__param"}>
                        <div className={"RecipeModalContent__hint"}>сложность</div>
                        <div>{getDifficulty(recipe?.difficulty)}</div>
                    </div>

                    <div className={'RecipeModalContent__right-header'}>
                        <div>
                            <h3>
                                <span><Translate value="common.recipe"/></span>
                            </h3>
                        </div>

                        <div className={'RecipeModalContent__right-border'}>

                        </div>
                    </div>
                    <div>
                        {recipe?.recipe.map((step, index) =>
                            <div className={"RecipeModalContent__step"}>
                                <span>{index + 1}.</span>
                                <div className={"RecipeModalContent__step-text"}>{step}</div>
                            </div>
                        )
                        }
                    </div>

                    <div className={"RecipeModalContent__bon-appetit"}>Приятного аппетита!</div>
                </div>


            </Container>) : null}
        </div>
    )
}

export default Recipe
