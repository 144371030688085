import './SearchModalContent.scss'
import {Container} from "react-bootstrap";
import _products from "../../Data/products.json"
import _news from "../../Data/news.json"
import _recipes from "../../Data/recipes.json"
import ProductData from "../../Model/ProductData";
import NewsData from "../../Model/NewsData";
import RecipeData from "../../Model/RecipeData";
import {useEffect, useState} from "react";
import Card from "../Card/Card";
import {
    hideModalProduct,
    hideModalRecipe,
    hideModalSearch,
    hideRequestModal, setModalObjectId,
    setModalWindow,
    setModalWindowProduct,
    setModalWindowRecipe
} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {ModalState} from "../../Redux/interfaces/interfaces";

interface SearchModalContentProps {
    modal: ModalState
    setModalWindowProduct: (isActive: boolean) => void
    setModalWindowRecipe: (isActive: boolean) => void
    hideModalSearch: () => void
    setModalWindow: (isActive: boolean) => void
    setModalObjectId: (objectId: number) => void
}

const SearchModalContent = (props: SearchModalContentProps) => {
    const products = _products as ProductData[];
    const news = _news as NewsData[];
    const recipes = _recipes as RecipeData[];

    const [searchValue, setSearchValue] = useState("")
    const [foundProducts, setFoundProducts] = useState<ProductData[]>([])
    const [foundNews, setFoundNews] = useState<NewsData[]>([])
    const [foundRecipes, setFoundRecipes] = useState<RecipeData[]>([])

    const [isShowAll, setShowAll] = useState<boolean>(true)
    const [isShowRecipes, setShowRecipes] = useState<boolean>(false)
    const [isShowProducts, setShowProducts] = useState<boolean>(false)
    const [isShowNews, setShowNews] = useState<boolean>(false)

    const hideAllSearch = () => {
        setShowRecipes(false)
        setShowProducts(false)
        setShowAll(false)
        setShowNews(false)
    }

    useEffect(() => {
        setFoundNews([])
        setFoundProducts([])
        setFoundRecipes([])
        if (searchValue.length >= 3)
            searchData(searchValue)
    }, [searchValue])

    const searchData = (search: string) => {
        setFoundProducts(products.filter(product => product.text.toLowerCase().includes(search.toLowerCase())
            || product.name.toLowerCase().includes(search.toLowerCase())))
        setFoundNews(news.filter(n => n.body.toLowerCase().includes(search.toLowerCase())
            || n.title.toLowerCase().includes(search.toLowerCase())))
        setFoundRecipes(recipes.filter(recipe => recipe.name.toLowerCase().includes(search.toLowerCase())
            || recipe.ingredients.filter(ing => ing.toLowerCase().includes(search.toLowerCase())).length > 0
            || recipe.recipe.filter(rec => rec.toLowerCase().includes(search.toLowerCase())).length > 0))

    }

    const productClickHandler = (productId: number) => {
        props.setModalObjectId(productId)
        props.setModalWindowProduct(true)
    }

    const recipeClickHandler = (recipeId: number) => {
        props.setModalObjectId(recipeId)
        props.setModalWindowRecipe(true)
    }

    const newsClickHandler = (newsId: number) => {
        props.setModalObjectId(newsId)
        props.setModalWindow(true)
    }

    return (
        <Container className={"SearchModalContent"}>
            <div className={"SearchModalContent__input"}>
                <input placeholder={"Поиск"} type={"text"} value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)}/>
            </div>
            <div className={"SearchModalContent__category"}>
                <div className={isShowAll ? "active" : ""}
                    onClick={() => {
                        hideAllSearch()
                        setShowAll(true)
                    }}>Все({foundNews.length + foundProducts.length + foundRecipes.length})
                </div>
                <div  className={isShowProducts ? "active" : ""} onClick={() => {
                    hideAllSearch()
                    setShowProducts(true)
                }}>Каталог({foundProducts.length})
                </div>
                <div  className={isShowRecipes ? "active" : ""} onClick={() => {
                    hideAllSearch()
                    setShowRecipes(true)
                }}>Рецепты({foundRecipes.length})
                </div>
                <div  className={isShowNews ? "active" : ""} onClick={() => {
                    hideAllSearch()
                    setShowNews(true)
                }}>Новости({foundNews.length})
                </div>

            </div>
            <div className={"SearchModalContent__results"}>
                {(isShowAll || isShowProducts) && foundProducts.map(foundProduct =>
                    <Card key={"product" + foundProduct.id} img={foundProduct.img} title={foundProduct.name} meatType={"каталог"}
                          onClick={() => {
                             productClickHandler(foundProduct.id)
                          }}/>)}
                {(isShowAll || isShowRecipes) && foundRecipes.map(foundRecipe =>
                    <Card key={"recipe" + foundRecipe.id} img={foundRecipe.img} title={foundRecipe.name}  meatType={"рецепты"}
                          onClick={() => recipeClickHandler(foundRecipe.id)}/>)}
                {(isShowAll || isShowNews) && foundNews.map(foundNews =>
                    <Card key={"news" + foundNews.id} img={foundNews.img} title={foundNews.title}  meatType={"новости"}
                          onClick={() => newsClickHandler(foundNews.id)}/>)}

            </div>
        </Container>

    )
}
const mapDispatchToProps = {
    setModalWindow: setModalWindow,
    hideRequestModal: hideRequestModal,
    hideModalRecipe: hideModalRecipe,
    hideModalProduct: hideModalProduct,
    hideModalSearch: hideModalSearch,
    setModalWindowProduct:setModalWindowProduct,
    setModalObjectId: setModalObjectId,
    setModalWindowRecipe:setModalWindowRecipe,

}
const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchModalContent)
