import React from 'react'
import './RecipeCard.scss'
import {Container, Row} from 'react-bootstrap'
import {Config} from '../../../../Config/Config'
import RecipeData from "../../../../Model/RecipeData";

interface RecipeCardProps {
    recipe: RecipeData
    isModal?: boolean
}

const RecipeCard = (props: RecipeCardProps) => {
    const renderDifficultScale = (difficult: number) => {
        let bulletElements: JSX.Element[] = []
        for (let i = 0; i < Config.recipeDifficultScale; i++) {
            bulletElements.push(
                <span
                    key={i}
                    className={`swiper-pagination-bullet ${i < difficult ? 'swiper-pagination-bullet-active' : ''}`}
                />
            )
        }

        return bulletElements
    }

    return (
        <Container fluid className={'RecipeCard'}>
            <Row className="RecipeCard__Row">
                <img className="img-fluid" src={props.isModal ? props.recipe.img_modal : props.recipe.img}
                     alt={props.recipe.name}/>
            </Row>
            <Row className="RecipeCard__Row">
                <div className="RecipeCard__title RecipeCard__line">{props.recipe.name}</div>
                <div className="RecipeCard__timeToCook RecipeCard__line">
                    <span>Время приготовления:</span> {props.recipe.cooking_time}
                </div>
                <div key={Math.random()} className="RecipeCard__difficulty RecipeCard__line d-flex">
                    <span>Сложность: </span>
                    <div className="RecipeCard__bullets">{renderDifficultScale(props.recipe.difficulty)}</div>
                </div>
                <div className="RecipeCard__servings RecipeCard__line">
                    <span>Порций: </span>
                    {props.recipe.servings}
                </div>
            </Row>
        </Container>
    )
}

export default RecipeCard
