import './App.scss'
import {Container} from 'react-bootstrap'
import {connect} from 'react-redux'
import {RootState} from './Redux'
import {ModalState, ToastState} from './Redux/interfaces/interfaces'
import ToastComponent from './SharedComponents/ToastComponent/ToastComponent'
import MobileMenu from './SharedComponents/MobileMenu/MobileMenu'
import AppRoutes from './AppRoutes/AppRoutes'
import ModalWindow from "./SharedComponents/ModalWindow/ModalWindow";
import SendMailModalContent from "./SharedComponents/SendMailModalContent/SendMailModalContent";
import SearchModalContent from "./SharedComponents/SearchModalContent/SearchModalContent";
import ProductModalContent from "./SharedComponents/ProductModalContent/ProductModalContent";
import React from "react";
import RecipeModalContent from "./SharedComponents/RecipeModalContent/RecipeModalContent";
import NewsCard from "./SharedComponents/NewsCard/NewsCard";

interface AppProps {
    toast: ToastState
    modal: ModalState
}

const App = (props: AppProps) => {

    return (

        <Container fluid className="App p-0">
            {props.modal.mobileMenu.isActive && <MobileMenu/>}
            {props.toast.isActive && <ToastComponent/>}
            {props.modal.modalRequestForm.isActive && <ModalWindow><SendMailModalContent/></ModalWindow>}
            {props.modal.modalWindowSearch.isActive && <ModalWindow><SearchModalContent/></ModalWindow>}
            {props.modal.modalWindowProduct.isActive && <ModalWindow><ProductModalContent/></ModalWindow>}
            {props.modal.modalWindowRecipe.isActive && (<ModalWindow><RecipeModalContent/></ModalWindow>)}
            {props.modal.modalWindow.isActive && (<ModalWindow><NewsCard/></ModalWindow>)}

            <AppRoutes/>

        </Container>
    )
}

const mapDispatchToProps =
    {}

const mapStateToProps = (state: RootState) => {
    const toast = state.toast
    const modal = state.modal
    return {
        toast,
        modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)