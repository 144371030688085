import React from 'react'
import './News.scss'
import {Container} from "react-bootstrap";
import Slider from "../../../SharedComponents/Slider/Slider";
import SliderSlide from "../../../Model/SliderSlide";
import NewsData from "../../../Model/NewsData";
import Card from "../../../SharedComponents/Card/Card";
import SendEmail from "../../../SharedComponents/SendEmail/SendEmail";
import {Translate} from "react-redux-i18n";
import {
    hideModalProduct,
    hideModalRecipe,
    hideModalSearch,
    hideRequestModal, setModalObjectId,
    setModalWindow, setModalWindowRecipe
} from "../../../Redux/actions/modal";
import {connect} from "react-redux";
import {RootState} from "../../../Redux";
import {ModalState} from "../../../Redux/interfaces/interfaces";
import _news from "../../../Data/news.json"


interface NewsProps {
    modal: ModalState
    setModalWindow: (isActive: boolean) => void
    setModalObjectId: (newsId: number) => void
}


const News = (props: NewsProps) => {
    const news = _news as NewsData[];

    const showModalWindow = (newsId: number) => {
        props.setModalObjectId(newsId)
        props.setModalWindow(true)
    }

    const slides: SliderSlide[] = news.map(n => {
            return {
                id: n.id,
                card:
                    <Card
                        onClick={() => showModalWindow(n.id)}
                        title={n.title} img={n.img}/>
            }
        }
    )

    return (
        <Container fluid className={"News"}>


            <div className={"BlockHeader__left"}>
                <h1><span><Translate value="menu.news"/></span></h1>
            </div>

            <Slider slides={slides} name={"News"}/>

            <SendEmail isPhone={false}/>
        </Container>
    )
}


const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}

const mapDispatchToProps = {
    setModalWindow: setModalWindow,
    hideRequestModal: hideRequestModal,
    hideModalRecipe: hideModalRecipe,
    hideModalProduct: hideModalProduct,
    hideModalSearch: hideModalSearch,
    setModalObjectId: setModalObjectId,
    setModalWindowRecipe: setModalWindowRecipe
}


export default connect(mapStateToProps, mapDispatchToProps)(News)


