import React, {MouseEventHandler} from 'react'
import './Button.scss'

interface ButtonProps {
  children?: React.ReactNode
  onClick: MouseEventHandler<HTMLDivElement>
}

const Button = (props: ButtonProps) => {
  return (
    <div onClick={props.onClick}  className="Button">
      {props.children}
    </div>
  )
}

export default Button