import React from 'react'
import './RecipeModalContent.scss'
import RecipeData from "../../Model/RecipeData";
import {Translate} from "react-redux-i18n";
import _recipes from "../../Data/recipes.json"
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {
    hideModalSearch
} from "../../Redux/actions/modal";

interface RecipeModalContentProps {
    recipeId: number
}

const RecipeModalContent = (props: RecipeModalContentProps) => {

    const recipes = _recipes as RecipeData[];

    const recipe = recipes.find(rec => rec.id === props.recipeId)

    const getDifficulty = (difficulty: number | undefined) => {
        if (difficulty === 1)
            return "очень легко"
        if (difficulty === 2)
            return "легко"
        if (difficulty === 3)
            return "средняя"
        if (difficulty === 4)
            return "сложно"
        if (difficulty === 5)
            return "очень сложно"
    }

    return (
        <div className="RecipeModalContent">
            <div className={"RecipeModalContent__img"}>
                <img className={"img-fluid"} src={`${recipe?.img}`} alt={recipe?.name}/>
            </div>
            <div className={"RecipeModalContent__info"}>
                <h2>{recipe?.name}</h2>
                <div className={"RecipeModalContent__param"}>
                    <div className={"RecipeModalContent__hint"}>ингредиенты</div>
                    <ul>
                        {recipe?.ingredients.map(ingredient => <li>{ingredient}</li>)}
                    </ul>
                </div>
                <div className={"RecipeModalContent__param"}>
                    <div className={"RecipeModalContent__hint"}>время приготовления</div>
                    <div>{recipe?.cooking_time}</div>
                </div>
                <div className={"RecipeModalContent__param"}>
                    <div className={"RecipeModalContent__hint"}>порции</div>
                    <div>{recipe?.servings}</div>
                </div>
                <div className={"RecipeModalContent__param"}>
                    <div className={"RecipeModalContent__hint"}>сложность</div>
                    <div>{getDifficulty(recipe?.difficulty)}</div>
                </div>

                <div className={'RecipeModalContent__right-header'}>
                    <div>
                        <h3>
                            <span><Translate value="common.recipe"/></span>
                        </h3>
                    </div>

                    <div className={'RecipeModalContent__right-border'}>

                    </div>
                </div>
                <div>
                    {recipe?.recipe.map((step, index) =>
                        <div className={"RecipeModalContent__step"}>
                            <span>{index + 1}.</span>
                            <div className={"RecipeModalContent__step-text"}>{step}</div>
                        </div>
                    )
                    }
                </div>

                <div className={"RecipeModalContent__bon-appetit"}>Приятного аппетита!</div>
            </div>


        </div>

        // </div>
    )
}
const mapDispatchToProps = {
    hideModalSearch: hideModalSearch,
}
const mapStateToProps = (state: RootState) => {
    const recipeId = state.modal.objectId
    return {
        recipeId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeModalContent)

