import Recipe1 from "../Pages/Main/Recipes/RecipesInfo/Recipe1/Recipe1"
import AuthIface from "../Redux/interfaces/AdditionalInterfaces/AuthIface"
import ModalElementIface from "../Redux/interfaces/AdditionalInterfaces/ModalElementIface"
import RecipeIface from "../Redux/interfaces/AdditionalInterfaces/RecipeIface"
import SlideProps from "../Redux/interfaces/AdditionalInterfaces/SlodeProps"

interface ConfigInterface {
  backendURL: string
  defaultAuth: AuthIface
  recipeDifficultScale: number
  recipes: RecipeIface[]
  slides: SlideProps[]
  // news: ModalElementIface[]
}

export const Config: ConfigInterface = {
  backendURL: "http://localhost:8088/",
  defaultAuth: {
    username: "",
    isAuth: false,
    // token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VyIiwicHJpdmlsZWdlcyI6WyJVU0VSIiwiUkVTVF9BUElfR0VUIl0sImlhdCI6MTY2MjQ3Mjg4NiwiZXhwIjoxNjYyNTE2MDg2fQ.VHnTnpdfBH2BCYX06Oby6GKOfD27TvsRfGWnT6Hdz98",
    token: ""
  },
  // news: [
  //   {
  //     type: 'news',
  //     img: '/img/news_extract_1.jpg',
  //     title: 'Халяль или нет: эксперт из Башкирии проверил «МясКо»',
  //     content: <Recipe1/>
  //   }
  // ],
  recipeDifficultScale: 5,
  recipes: [
    {
      id: 0,
      img: '/img/rec_1.jpg',
      title: 'Плов с бараниной',
      timeToCook: '2ч',
      difficulty: 4,
      servings: 7,
    },
    {
      id: 1,
      img: '/img/rec_2.jpg',
      title: 'Баранья рулька с картофельным пюре',
      timeToCook: '1ч',
      difficulty: 4,
      servings: 1,
    },
    {
      id: 2,
      img: '/img/rec_3.jpg',
      title: 'Гуляш из говядины с пюре и подливой',
      timeToCook: '1ч 20м',
      difficulty: 2,
      servings: 2,
    },
    {
      id: 3,
      img: '/img/rec_4.jpg',
      title: 'Бараньи ребрышки, жаренные на сковороде',
      timeToCook: '2ч 30м',
      difficulty: 2,
      servings: 3,
    },
    {
      id: 4,
      img: '/img/rec_5.jpg',
      title: 'Шашлык из баранины',
      timeToCook: '1ч',
      difficulty: 3,
      servings: 6,
    },
  ],
  slides: [
    {
      id: 1,
      title: 'Если мясо - то «МясКо»',
      img: '/img/back1.jpg',
      slideNum: '01',
      additionalCSS: 'slideBackgroundProps',
    },
    {
      id: 2,
      title: 'Работаем с HoReCa и сетями',
      img: '/img/back2.jpg',
      slideNum: '02',
      additionalCSS: '',
    },
    {
      id: 3,
      title: 'Фирменные рецепты',
      img: '/img/back3.jpg',
      slideNum: '03',
      additionalCSS: '',
    },
    {
      id: 4,
      title: 'Халяль 100%',
      img: '/img/back4.jpg',
      slideNum: '04',
      additionalCSS: '',
    },
  ]
}