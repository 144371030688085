import React from 'react'
import {Routes, Route} from 'react-router-dom'
import MainLayout from '../Layouts/MainLayout/MainLayout'
import Main from '../Pages/Main/Main'
import NotFound from '../Pages/NotFound/NotFound'
import Contacts from "../Pages/Contacts/Contacts";
import Catalog from "../Pages/Catalog/Catalog";
import Partners from "../Pages/Partners/Partners";
import Recipe from "../Pages/Recipe/Recipe";
import Recipes from "../Pages/Recipes/Recipes";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainLayout/>}>
                <Route index element={<Main/>}/>
                <Route path="contacts" element={<Contacts/>}/>
                <Route path="partners" element={<Partners/>}/>
                <Route path="catalog" element={<Catalog/>}/>
                <Route path="recipes" element={<Recipes/>}/>
                <Route path={`recipe/:recipeId`} element={<Recipe/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    )
}

export default AppRoutes
