import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import './Block1.scss'

// import Swiper core and required modules
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import {Config} from '../../../Config/Config'

interface Block1Props {
}

const Block1 = (props: Block1Props) => {
    return (
        <Container fluid className="Block1">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                loop={true}
                // navigation
                slidesPerView={1}
                autoplay={{
                    delay: 95000,
                }}
                pagination={{
                    clickable: true,
                }}
            >
                {Config.slides.map((slide) => {
                    return (
                        <SwiperSlide key={slide.id}>
                            <Container
                                fluid
                                className={`Block1__slide ${slide.additionalCSS}`}
                                id="slide"
                                style={{
                                    background: `url(${slide.img})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <Container className="Block1__title">
                                    <Row className="Block1__Row">
                                        <Col xs={7} className="Block1__Col d-flex align-items-center">
                                            <h1>{slide.title}</h1>
                                        </Col>
                                    </Row>
                                </Container>
                            </Container>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Container>
    )
}

export default Block1
