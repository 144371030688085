import {
  HIDE_ALL_MODAL,
  HIDE_MODAL_WINDOW_PRODUCT,
  HIDE_MODAL_WINDOW_RECIPE,
  HIDE_MODAL_WINDOW_SEARCH,
  HIDE_REQUESTFORM_MODAL,
  SET_MOBILE_MENU,
  SET_MODAL_OBJECT_ID,
  SET_MODAL_WINDOW,
  SET_MODAL_WINDOW_PRODUCT,
  SET_MODAL_WINDOW_RECIPE,
  SET_MODAL_WINDOW_SEARCH,
  SHOW_REQUESTFORM_MODAL
} from '../constants/ActionTypes'
import {ModalState} from '../interfaces/interfaces'
import {ModalActionType} from '../interfaces/modal'

const initialState: ModalState = {
  modalRequestForm: {
    isActive: false,
  },
  mobileMenu: {
    isActive: false,
  },
  modalWindow: {
    isActive: false,
  },
  modalWindowProduct: {
    isActive: false,
  },
  modalWindowRecipe: {
    isActive: false,
  },
  modalWindowSearch: {
    isActive: false,
  },
  objectId: -1,
}

const modal = (state: ModalState = initialState, action: ModalActionType) => {
  switch (action.type) {
    case SHOW_REQUESTFORM_MODAL:
      return {
        ...state,
        modalRequestForm: {
          isActive: true,
        },
      }
    case HIDE_REQUESTFORM_MODAL:
      return {
        ...state,
        modalRequestForm: {
          isActive: false,
        },
      }
    case HIDE_MODAL_WINDOW_PRODUCT:
      return {
        ...state,
        modalWindowProduct: {
          isActive: false,
        },
      }
    case HIDE_MODAL_WINDOW_RECIPE:
      return {
        ...state,
        modalWindowRecipe: {
          isActive: false,
        },
      }
    case HIDE_MODAL_WINDOW_SEARCH:
      return {
        ...state,
        modalWindowSearch: {
          isActive: false,
        },
      }
    case HIDE_ALL_MODAL:
      return {
        ...state,
        modalRequestForm: {
          isActive: false,
        },
        modalWindow: {
          isActive: false,
        },
        modalWindowProduct: {
          isActive: false,
        },
        modalWindowRecipe: {
          isActive: false,
        },
      }
    case SET_MOBILE_MENU:
      return {
        ...state,
        mobileMenu: {
          isActive: action.isActive,
        },
      }
    case SET_MODAL_WINDOW:
      return {
        ...state,
        modalWindow: {
          isActive: action.isActive,
        },
      }
    case SET_MODAL_WINDOW_PRODUCT:
      return {
        ...state,
        modalWindowProduct: {
          isActive: action.isActive,
        },
      }
    case SET_MODAL_WINDOW_RECIPE:
      return {
        ...state,
        modalWindowRecipe: {
          isActive: action.isActive,
        },
      }
    case SET_MODAL_WINDOW_SEARCH:
      return {
        ...state,
        modalWindowSearch: {
          isActive: action.isActive,
        },
      }

    case SET_MODAL_OBJECT_ID:
      return {
        ...state,
        objectId: action.objectId
      }
    default:
      return state
  }
}

export default modal
