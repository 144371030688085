import React from 'react'
import './RoundButton.scss'

interface RoundButtonProps {
    children?: React.ReactNode
    href: string;
}

const RoundButton = (props: RoundButtonProps) => {
    return (
        <a href={props.href} target="_blank">
            <div className="RoundButton">
                {props.children}
            </div>
        </a>
    )
}

export default RoundButton