import {
    SET_APP_ERROR,
    SET_APP_LOADING,
    SET_APP_MARKETUSER,
    SET_APP_PAGINATION,
    SET_APP_PRODUCTS,
    SET_APP_PRODUCT_CATEGORIES,
    SET_APP_TARIFF

} from "../constants/ActionTypes";
import {MarketUser} from "../interfaces/AdditionalInterfaces/MarketUser";
import Pagination from "../interfaces/AdditionalInterfaces/Pagination";
import Product from "../interfaces/AdditionalInterfaces/Product";
import ProductCategory from "../interfaces/AdditionalInterfaces/ProductCategory";
import {DispatchCallback, setLocale} from "react-redux-i18n";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../index";
import {EmailData} from "../../Model/EmailData";

export const sendEmail = (data: EmailData): ThunkAction<void, RootState, null, any> => {
    return async (dispatch) => {
        try {
            dispatch(setAppError(""))
            dispatch(setAppLoading(true))
            const res = await fetch('https://mysko.ru/api/index.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (!res.ok) {
                const resData: Error = await res.json()
                setAppError(resData.message)
                dispatch(setAppLoading(false))
            }
            dispatch(setAppLoading(false))
            return await res.json()
        } catch (error) {
            dispatch(setAppLoading(false))
            dispatch(setAppError("Ошибка отправки email"))
        }
    }
}

export const sendFile = (file: File | null | undefined): ThunkAction<void, RootState, null, any> => {
    return async (dispatch) => {
        try {

            const data = new FormData()
            if (file) {
                data.append('file', file?.slice())
            }
            dispatch(setAppError(""))
            dispatch(setAppLoading(true))
            const res = await fetch('https://mysko.ru/api/upload.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: data,

            })
            if (!res.ok) {
                const resData: Error = await res.json()
                setAppError(resData.message)
                dispatch(setAppLoading(false))
            }
            dispatch(setAppLoading(false))
            return await res.json()
        } catch (error) {
            dispatch(setAppLoading(false))
            dispatch(setAppError("Ошибка отправки файла"))
        }


    }
}

export const setAppLoading = (loading: boolean) => ({
    type: SET_APP_LOADING,
    loading,
})

export const setAppError = (error: string) => ({
    type: SET_APP_ERROR,
    error,
})

export const setAppTariff = (tariff: string) => ({
    type: SET_APP_TARIFF,
    tariff,
})

export const setAppMarketUser = (marketUser: MarketUser | null) => ({
    type: SET_APP_MARKETUSER,
    marketUser,
})

export const setAppProducts = (products: Product[]) => ({
    type: SET_APP_PRODUCTS,
    products,
})

export const setAppProductCategories = (productCategories: ProductCategory[]) => ({
    type: SET_APP_PRODUCT_CATEGORIES,
    productCategories,
})

export const setAppPagination = (pagination: Pagination) => ({
    type: SET_APP_PAGINATION,
    pagination,
})

export function setLocaleWithFallback(desiredLocale: string) {
    const finalLocale = Object.keys({en: "English", ru: "Русский"}).includes(desiredLocale)
        ? desiredLocale
        : "ru";
    return (dispatch: (arg0: DispatchCallback<any>) => string) => dispatch(setLocale(finalLocale));
}
