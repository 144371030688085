import React from 'react'
import {Container} from 'react-bootstrap'
import Block1 from './Block1/Block1'
import './Main.scss'
import News from "./News/News";
import Recipes from "./Recipes/Recipes";
import Novelty from "./Novelty/Novelty";
import About from "./About/About";
import * as Scroll from 'react-scroll'

interface MainProps {
}

const Main = () => {

    return (
        <Container fluid className="Main">
            <Block1/>
            <Scroll.Element name="About">
                <section>
                    <About/>
                </section>
            </Scroll.Element>

            <Novelty/>
            <Scroll.Element name="Recipes">
                <section>
                    <Recipes/>
                </section>
            </Scroll.Element>
            <Scroll.Element name="News">
                <section>
                    <News/>
                </section>
            </Scroll.Element>
        </Container>
    )
}

export default Main
