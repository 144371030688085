import React, {useState} from 'react'
import {Container} from 'react-bootstrap'
import './Contacts.scss'
import {Placemark, YMaps, Map} from "react-yandex-maps";
import LoaderCircle from "../../SharedComponents/LoaderCircle/LoaderCircle";
import SendEmail from "../../SharedComponents/SendEmail/SendEmail";
import BackgroundHeader from "../../SharedComponents/BackgroundHeader/BackgroundHeader";
import ScrollToTop from "../../SharedComponents/ScrollToTop/ScrollToTop";
import {Translate} from "react-redux-i18n";

const Contacts = () => {

    const [isLoading, setIsLoading] = useState(true);
    ScrollToTop()
    return (
        <div className="Contacts p-0">
            <div className={"BackgroundHeader__title container"}><Translate value={"menu.contacts"}/></div>
            <BackgroundHeader img={"/img/contacts.jpg"}/>
            <Container className={"Contacts__card mt-3 mb-3"}>
                <h2>ООО «Мяско»</h2>
                <div className={"mt-3 mb-3"}>эл. почта: <span className={"Contacts__email"}><a
                    href="mailto:mysko@mysko.ru">mysko@mysko.ru</a></span></div>
                <div className={"mt-3 mb-3"}>адрес: <span className={"Contacts__address"}>
                        Астраханская область, Лиманский район, р. п. Лиман, ул. Светлая, дом 2
                    </span>
                </div>
            </Container>
            {isLoading && <LoaderCircle/>}
            <div className="Contacts__map-container">
                <YMaps>
                    <Map
                        className="Contacts__map"
                        onLoad={() => setIsLoading(false)}
                        defaultState={{center: [45.763651, 47.211062], zoom: 13.5}}
                    >
                        <Placemark geometry={[45.763651, 47.211062]}/>
                    </Map>
                </YMaps>
            </div>

            <SendEmail isPhone={false}/>

        </div>
    )
}

export default Contacts
