import React from 'react'
import './FAQ.scss'
import Accordion from 'react-bootstrap/Accordion';
import SendEmail from "../SendEmail/SendEmail";

interface FaqProps {
}

const FAQ = (props: FaqProps) => {

    return (
        <div className={"Faq"}>
            <div className={"Faq__header"}>
                <h1>Часто задаваемые вопросы</h1>

            </div>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Предоставляются ли документы на продукцию при поставке?</Accordion.Header>
                    <Accordion.Body>
                        На продукцию ООО «Мяско» предоставляется полный пакет документов, в том числе ветеринарное
                        свидетельство ГИС «Меркурий».
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Есть ли доставка в выходные дни?</Accordion.Header>
                    <Accordion.Body>
                        Заказать продукцию ООО «Мяско» можно на любой день, завод работает ежедневно.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Как связаться с представительством завода «Мяско» в моем
                        регионе?</Accordion.Header>
                    <Accordion.Body>
                        Вы можете связаться с представителями компании по телефонам, указанным ниже:
                        п. Лиман — 8 961 300 67 60, г. Астрахань — 8 927 072 63 50.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Какие документы необходимы для заключения договора с «Мяско»?</Accordion.Header>
                    <Accordion.Body>
                        Устав документы.
                        Карточка с реквизитами компании.
                        Ф.И.О., телефон и почта ответственного за заказы лица.
                        Ф.И.О., телефон ответственного за приемку товара.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Проводите ли Вы обучение персонала, как предлагать Вашу
                        продукцию?</Accordion.Header>
                    <Accordion.Body>
                        Проводим консультацию с персоналом. Вы можете посетить завод «Мяско», посмотреть, как проходят
                        технологические процессы, упаковка и погрузка продукции.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Предоставляете ли Вы информационные материалы по Вашей продукции?</Accordion.Header>
                    <Accordion.Body>
                        При товаре-обороте от 1 млн рублей готовы предоставить рекламные материалы.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Как формируются цены для партнеров?</Accordion.Header>
                    <Accordion.Body>
                        Цена по запросу.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <SendEmail isPhone={true}/>
        </div>
    )
}


export default FAQ