import React, {FormEvent, FormEventHandler, useState} from 'react'
import './SendMailModalContent.scss'
import {Col, Row, Spinner} from "react-bootstrap";
import InputString from "../InputString/InputString";
import FormInput from "../FormInput/FormInput";
import InputTextArea from "../InputTextArea/InputTextArea";
import Button from "../Button/Button";
import {Translate} from "react-redux-i18n";
import {sendEmail, sendFile} from "../../Redux/actions/app";
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {AppState} from "../../Redux/interfaces/interfaces";
import {EmailData} from "../../Model/EmailData";

interface SendMailModalContentProps {
    app: AppState
    sendEmail: (data: EmailData) => void
    sendFile: (file: File | null | undefined) => void
}

const SendMailModalContent = (props: SendMailModalContentProps) => {

    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [isPhoneError, setPhoneError] = useState<boolean>(false);
    const [isAgreementError, setAgreementError] = useState<boolean>(false);
    const [isEmailError, setEmailError] = useState<boolean>(false);
    const [isNameError, setNameError] = useState<boolean>(false);
    const [isEmailSend, setEmailSend] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>();

    const nameChangeHandle = (value: string) => {
        setNameError(false)
        setName(value)
    }

    const phoneChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneError(false)
        setPhone(event.target.value)
    }
    const emailChangeHandle = (value: string) => {
        setEmailError(false)
        setEmail(value)
    }
    const isAgreeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreementError(false)
        setIsAgree(event.target.checked)
    }
    const chooseFileHandler = (file: File | null | undefined) => {
        setFile(file)
    }
    const handleSubmit = (event : FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        buttonClickHandle()
    };

    const buttonClickHandle = async () => {
        setEmailSend(false)
        if (name.trim().length === 0)
            setNameError(true)
        if (phone.replace("_", "").length < 17)
            setPhoneError(true)
        if (!isAgree)
            setAgreementError(true)
        if (!validateEmail(email))
            setEmailError(true)

        if (name.trim().length > 0
            && phone.replace("_", "").length === 17
            && isAgree
            && validateEmail(email)) {

            // await props.sendFile(file)

            props.sendEmail({
                email,
                phone,
                name,
                message
            })
            setEmailSend(true)
        }
    }
    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <form name={"sendEmailForm"} onSubmit={handleSubmit}>
            <div className={"SendMailModalContent"}>
                <h2>Напишите нам</h2>
                <InputString title={"имя и фамилия"} value={name}
                             controlChangeHandler={event => nameChangeHandle(event)}/>
                <small className={isNameError ? "d-block" : "d-none"} style={{color: 'red'}}>Введите ваше имя</small>
                <Row>
                    <Col className={"mt-3"} md={6}>
                        <FormInput
                            mask="8 (999) 999-99-99"
                            value={phone}
                            onChange={(event) => phoneChangeHandle(event)}
                            placeholder="телефон"
                            width="100%"
                            height="40px"
                            type="tel"
                            icon="phone.svg"
                        />

                        <small className={isPhoneError ? "d-block" : "d-none"} style={{color: 'red'}}>Введите корректный
                            номер телефона</small>
                    </Col>
                    <Col className={"mt-3"} md={6}>
                        <InputString title={"эл. почта"} value={email}
                                     controlChangeHandler={event => emailChangeHandle(event)}/>
                        <small className={isEmailError ? "d-block" : "d-none"} style={{color: 'red'}}>Введите корректный
                            email</small>
                    </Col>
                </Row>

                <div className={"mt-3"}>
                    <InputTextArea title={"сообщений"} value={message}
                                   controlChangeHandler={value => setMessage(value)}/>
                </div>

                <div className={"SendMailModalContent__file-upload"}>
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Прикрепить файл
                    </label>
                    <input id="file-upload" type="file" multiple={false}
                           onChange={event => chooseFileHandler(event.target.files?.item(0))}/>
                    <div>{file?.name}</div>
                </div>

                <div className={"SendMailModalContent__agreement"}>
                    <div className={"d-flex"} id={"isAgree"}>
                        <input
                            id={"agreement"}
                            checked={isAgree}
                            onChange={(event) => isAgreeChangeHandler(event)}
                            type="checkbox" name="switch" className="check"/>
                    </div>
                    <div className={"SendMailModalContent__agreement-label"}><label htmlFor={"agreement"}>
                        Я соглашаюсь на обработку персональных данных </label></div>

                </div>
                <small className={isAgreementError ? "d-block mb-3" : "d-none"} style={{color: 'red'}}>
                    Для отправки письма вы должны согласиться на обработку персональных
                    данных</small>

                <div className={"mt-3"}>
                    <button type={"submit"}>
                        <Button onClick={() => {}}>
                            {props.app.loading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : null}
                            <Translate value="common.send_email"/>
                        </Button>
                    </button>

                </div>

                <small className={props.app.error.length > 0 ? "d-block" : "d-none"} style={{color: 'red'}}>
                    {props.app.error}</small>
                <small
                    className={props.app.error.length === 0 && isEmailSend && !props.app.loading ? "d-block" : "d-none"}
                    style={{color: 'green'}}>
                    Сообщение успешно отправлено</small>
            </div>
        </form>

    )
}
const mapDispatchToProps = {
    sendEmail: sendEmail,
    sendFile: sendFile
}
const mapStateToProps = (state: RootState) => {
    const app = state.app
    return {
        app,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SendMailModalContent)
