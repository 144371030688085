import React from 'react'
import './ProductModalContent.scss'
import ProductData from "../../Model/ProductData";
import _recipes from "../../Data/recipes.json"
import _products from "../../Data/products.json"
import RecipeData from "../../Model/RecipeData";
import Card from "../Card/Card";
import {
    hideModalProduct,
    hideModalRecipe,
    hideModalSearch,
    hideRequestModal, setModalObjectId,
    setModalWindow, setModalWindowRecipe
} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";

interface ProductModalContentProps {
    productId: number
    setModalObjectId: (recipeId: number) => void
    hideModalProduct: () => void
    setModalWindowRecipe: (isActive: boolean) => void
}

const ProductModalContent = (props: ProductModalContentProps) => {
    const recipes = _recipes as RecipeData[];
    const products = _products as ProductData[];

    const product = products.find(prod => prod.id === props.productId)


    const recipeClickHandler = (recipeId: number): void => {
        props.hideModalProduct()
        props.setModalObjectId(recipeId)
        props.setModalWindowRecipe(true)

    }

    return (
        <div className="ProductModalContent">


            <div className={"ProductModalContent__img"}>
                {product?.type === "баранина"
                    ? <div className={"ProductModalContent__halal"}>
                        <img src="/icons/alhalal-logo-halal.svg" alt="halal"/>
                    </div>
                    : null
                }
                <img className={"img-fluid"} src={`${product?.img}`} alt={product?.name}/>
            </div>
            <div className={"ProductModalContent__info"}>
                <h2>{product?.name}</h2>
                <p>{product?.text}</p>
                <div className={"ProductModalContent__param"}>
                    <div className={"ProductModalContent__hint"}>вес продукта</div>
                    <div>{product?.weight}</div>
                </div>
                <div className={"ProductModalContent__param"}>
                    <div className={"ProductModalContent__hint"}>срок годности</div>
                    <div>{product?.expiration_date}</div>
                </div>

                <div className={"ProductModalContent__param"}>
                    <div className={"ProductModalContent__hint"}>условия хранения
                        охлажденного продукта
                    </div>
                    <div>{product?.store_conditions_cold}</div>
                </div>
                <div className={"ProductModalContent__param"}>
                    <div className={"ProductModalContent__hint"}>условия хранения
                        замороженного продукта
                    </div>
                    <div>{product?.store_conditions_freeze}</div>
                </div>

                <div className={"ProductModalContent__param"}>
                    <div className={"ProductModalContent__hint"}>упаковка</div>
                    <div>{product?.package}</div>
                </div>
                {recipes
                    .filter(recipe => recipe.product_ids.includes(props.productId)).length > 0
                    ? <div className={"ProductModalContent__recipes"}>
                        <h3>Рецепты с этим продуктом</h3>
                        <div className={"ProductModalContent__cards-container"}>
                            {recipes
                                .filter(recipe => recipe.product_ids.includes(props.productId))
                                .map(recipe => {
                                    return <Card
                                        key={recipe.id}
                                        img={recipe.img}
                                        title={recipe.name}
                                        onClick={() => recipeClickHandler(recipe.id)}
                                    />
                                })}
                        </div>
                    </div>
                    : null}


            </div>

        </div>
    )
}
const mapDispatchToProps = {
    setModalWindow: setModalWindow,
    hideRequestModal: hideRequestModal,
    hideModalRecipe: hideModalRecipe,
    hideModalProduct: hideModalProduct,
    hideModalSearch: hideModalSearch,
    setModalObjectId: setModalObjectId,
    setModalWindowRecipe: setModalWindowRecipe
}
const mapStateToProps = (state: RootState) => {
    const productId = state.modal.objectId
    return {
        productId,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductModalContent)

