import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './MainFooter.scss'
import SocialButtonsGroup from '../../../SharedComponents/SocialButtonsGroup/SocialButtonsGroup'
import { Link } from 'react-router-dom'
import { Translate } from 'react-redux-i18n'
import * as Scroll from 'react-scroll'

const MainFooter = () => {
  const scrollToElement: (element: string) => void = (element: string) => {
    Scroll.scroller.scrollTo(element, {
      duration: 500,
      delay: 0,
      smooth: true,
    })
  }
  return (
    <Container className="MainFooter">
      <div className="Block1_wa">
        <a href="https://wa.me/79613006760">
          <img className="img-fluid" src="/icons/whatsup.svg" alt="" />
        </a>
      </div>
      <Row className={'MainFooter__top'}>
        <Col lg={2} sm={4} xs={'auto'} className="MainFooter__logo">
          <Link to="/">
            <img src={window.location.origin + '/icons/logo.svg'} alt="logo.svg" />
          </Link>
        </Col>
        <Col lg={4} sm={8} xs={'auto'} className="MainFooter__menu">
          <ul>
            <li>
              <Link to="/catalog">
                <Translate value="menu.catalog" />
              </Link>
            </li>
            <li>
              <Link to="/partners">
                <Translate value="menu.partners" />
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollToElement('About')} to="/">
                <Translate value="menu.about" />
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollToElement('News')} to="/">
                <Translate value="menu.news" />
              </Link>
            </li>
            <li>
              <Link to="/recipes">
                <Translate value="menu.recipes" />
              </Link>
            </li>
            <li>
              <Link to="/contacts">
                <Translate value="menu.contacts" />
              </Link>
            </li>
          </ul>
          {/* <Row>
                        <Col>
                            <ul>
                                <li><Link to="/catalog"><Translate value="menu.catalog"/></Link></li>
                                <li><Link to="/partners"><Translate value="menu.partners"/></Link></li>
                                <li>
                                    <Link onClick={() => scrollToElement('About')} to="/">
                                        <Translate value="menu.about"/>
                                    </Link>

                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <ul>
                                <li><Link onClick={() => scrollToElement('News')} to="/">
                                    <Translate value="menu.news"/>
                                </Link></li>
                                <li><Link to="/recipes">
                                    <Translate value="menu.recipes"/>
                                </Link></li>
                                <li><Link to="/contacts"><Translate value="menu.contacts"/></Link></li>
                            </ul>
                        </Col>
                    </Row> */}
        </Col>
        <Col lg={3} className={' mt-lg-0 mb-lg-0 mt-3 mb-3'}>
          <div className={'MainFooter__email'}>
            <a href={'mailto:mysko@mysko.ru'}>mysko@mysko.ru</a>
          </div>
          <div className={'MainFooter__address'}>
            Астраханская область, Лиманский район, р. п. Лиман, ул. Светлая, дом 2
          </div>
        </Col>
        <Col lg={3} className={'d-flex mt-lg-0 mb-lg-0 mt-3 mb-3'}>
          <SocialButtonsGroup />
        </Col>
      </Row>
      <Row className={'MainFooter__bottom w-100 '}>
        <Col xs={'auto'} className={'MainFooter__politics'}>
          <a href="/politica.pdf" target={'_blank'}>
            Политика конфиденциальности
          </a>
        </Col>
        <Col xs={'auto'} className={'MainFooter__politics'}>
          <a href="/sla.pdf" target={'_blank'}>
            Специальная оценка труда
          </a>
        </Col>
        <Col xs={'auto'} className={'MainFooter__copyright'}>
          Сайт разработан
          <a href={'https://deedesign.ru/'} target={'_blank'}>
            {' '}
            Deedesign
          </a>
        </Col>
      </Row>
      <></>
    </Container>
  )
}

export default MainFooter
