import React from 'react'
import './Novelty.scss'
import {Container,} from "react-bootstrap";
import Slider from "../../../SharedComponents/Slider/Slider";
import SliderSlide from "../../../Model/SliderSlide";
import Card from "../../../SharedComponents/Card/Card";
import _products from "../../../Data/products.json";
import ProductData from "../../../Model/ProductData";
import {setModalObjectId, setModalWindowProduct} from "../../../Redux/actions/modal";
import {RootState} from "../../../Redux";
import {connect} from "react-redux";
import {ModalState} from "../../../Redux/interfaces/interfaces";

interface NoveltyProps {
    setModalObjectId: (productId: number) => void
    setModalWindowProduct: (isVisible: boolean) => void
    modal: ModalState
}


const Novelty = (props: NoveltyProps) => {
    const products = _products as ProductData[];

    const showModalWindow = (productId: number) => {
        props.setModalObjectId(productId)
        props.setModalWindowProduct(true)
    }

    const slides: SliderSlide[] = products
        .filter(product => product.isNovelty)
        .map(product => {
                return {
                    id: product.id,
                    card:
                        <Card
                            onClick={() => showModalWindow(product.id)}
                            img={product.img}
                            title={product.name}
                            meatType={product.type}
                            weight={product.weight}/>
                }
            }
        )
    return (
        <Container fluid className={"Novelty"}>
            <div className={"BlockHeader__left"}>
                <h1><span>Новинки</span></h1>
            </div>

            <Slider slides={slides} name={"Novelty"}/>
        </Container>
    )
}

const mapDispatchToProps = {
    setModalWindowProduct: setModalWindowProduct,
    setModalObjectId: setModalObjectId
}
const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Novelty)