import {
  HIDE_ALL_MODAL, HIDE_MODAL_WINDOW_PRODUCT, HIDE_MODAL_WINDOW_RECIPE, HIDE_MODAL_WINDOW_SEARCH,
  HIDE_REQUESTFORM_MODAL,
  SET_MOBILE_MENU, SET_MODAL_OBJECT_ID,
  SET_MODAL_WINDOW,
  SET_MODAL_WINDOW_PRODUCT, SET_MODAL_WINDOW_RECIPE, SET_MODAL_WINDOW_SEARCH,
  SHOW_REQUESTFORM_MODAL
} from "../constants/ActionTypes"

export const showRequestModal = () => ({
  type: SHOW_REQUESTFORM_MODAL
})

export const hideRequestModal = () => ({
  type: HIDE_REQUESTFORM_MODAL
})
export const hideModalProduct = () => ({
  type: HIDE_MODAL_WINDOW_PRODUCT
})
export const hideModalRecipe = () => ({
  type: HIDE_MODAL_WINDOW_RECIPE
})
export const hideModalSearch = () => ({
  type: HIDE_MODAL_WINDOW_SEARCH
})

export const setMobileMenu = (isActive: boolean) => ({
  type: SET_MOBILE_MENU,
  isActive,
})

export const setModalWindow = (isActive: boolean) => ({
  type: SET_MODAL_WINDOW,
  isActive,
})

export const setModalWindowProduct = (isActive: boolean) => ({
  type: SET_MODAL_WINDOW_PRODUCT,
  isActive,
})

export const setModalWindowRecipe = (isActive: boolean) => ({
  type: SET_MODAL_WINDOW_RECIPE,
  isActive,
})

export const setModalWindowSearch = (isActive: boolean) => ({
  type: SET_MODAL_WINDOW_SEARCH,
  isActive,
})

export const hideAllModal = () => ({
  type: HIDE_ALL_MODAL
})

export const setModalObjectId = (objectId: number) => ({
  type: SET_MODAL_OBJECT_ID,
  objectId
})