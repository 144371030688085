import React from 'react'
import './Slider.scss'
import {Container} from "react-bootstrap";
import {A11y, Autoplay, Navigation, Scrollbar} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";


import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import SliderSlide from "../../Model/SliderSlide";


interface SliderProps {
    slides: SliderSlide[]
    name: string
}

const Slider = (props: SliderProps) => {

    return (
        <Container className={"Slider"}>
            <div className="swiper-controls">
                <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + 'icons/arrow_left.svg'})`
                }} className="swiper-prev swiper-btn" id={`swiper${props.name}PrevArrow`}/>
                <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + 'icons/arrow_right.svg'})`
                }} className="swiper-next swiper-btn" id={`swiper${props.name}NextArrow`}/>
            </div>
            <Swiper
                modules={[Navigation, Scrollbar, A11y, Autoplay]}
                navigation={{
                    nextEl: `#swiper${props.name}NextArrow`,
                    prevEl: `#swiper${props.name}PrevArrow`,
                }}
                slidesPerView={'auto'}
                spaceBetween={25}
            >
                {props.slides.map((slide) => {
                    return (
                        <SwiperSlide key={slide.id}>
                            <div className="Slider__slide" id="slide">
                                {slide.card}
                            </div>
                        </SwiperSlide>
                    )
                }
                )}
            </Swiper>
        </Container>

    )
}

export default Slider

