import React from 'react'
import './Recipes.scss'
import _recipes from "../../Data/recipes.json"
import ScrollToTop from "../../SharedComponents/ScrollToTop/ScrollToTop";
import RecipeData from "../../Model/RecipeData";
import {Translate} from "react-redux-i18n";
import BackgroundHeader from "../../SharedComponents/BackgroundHeader/BackgroundHeader";
import {Container} from "react-bootstrap";
import RecipeCard from "../Main/Recipes/RecipeCard/RecipeCard";
import {ModalState} from "../../Redux/interfaces/interfaces";
import {setModalObjectId, setModalWindowRecipe} from "../../Redux/actions/modal";
import {RootState} from "../../Redux";
import {connect} from "react-redux";

interface RecipesProps {
    modal: ModalState
    setModalWindowRecipe: (isActive: boolean) => void
    setModalObjectId: (recipeId: number) => void
}

const Recipes = (props: RecipesProps) => {
    ScrollToTop()
    const recipes = _recipes as RecipeData[];

    const showModalWindow = (recipeId: number) => {
        props.setModalObjectId(recipeId)
        props.setModalWindowRecipe(true)
    }


    return (
        <div className="Recipes p-0">
            <div className={"BackgroundHeader__title container"}><Translate value={"menu.recipes"}/></div>
            <BackgroundHeader img={"/img/recipe_bg.jpg"}/>
            <Container className={"Recipes__cards"}>
                {recipes.map((recipe) =>
                    <div onClick={() => showModalWindow(recipe.id)}>
                        <RecipeCard recipe={recipe}/>
                    </div>
                )}
            </Container>
        </div>
    )
}

const mapDispatchToProps = {
    setModalWindowRecipe: setModalWindowRecipe,
    setModalObjectId: setModalObjectId
}

const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipes)

