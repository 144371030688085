import React from 'react'
import './SocialButtonsGroup.scss'
import RoundButton from "../RoundButton/RoundButton";



const SocialButtonsGroup = () => {
  return (
    <div  className="SocialButtonsGroup">
      <div className="SocialButtonsGroup__buttons d-flex">
        <RoundButton href="https://wa.me/79613006760">
          <img width={"20px"} src={window.location.origin + "/icons/whats.svg"} alt="vk"/>
        </RoundButton>
        <RoundButton href="https://vk.com/myskoru">
          <img width={"23px"} src={window.location.origin + "/icons/vk.svg"} alt="vk"/>
        </RoundButton>
        <RoundButton href="https://ok.ru/group/70000000164744">
          <img width={"13px"} src={window.location.origin + "/icons/ok.svg"} alt="vk"/>
        </RoundButton>
      </div>
    </div>
  )
}
export default SocialButtonsGroup

