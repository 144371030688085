import React from 'react'
import InputMask from 'react-input-mask'

import './FormInput.scss'

interface FormInputProps {
  mask?: string
  value: string
  icon: string
  width: string
  height: string
  type: string
  placeholder: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface FormInputState {
  value: string
}

class FormInput extends React.Component<FormInputProps, FormInputState> {
  constructor(props: FormInputProps) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }

  changeInputHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.currentTarget.value })
    this.props.onChange(event)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.mask ? (
          <InputMask
            mask={this.props.mask}
            onChange={(event) => this.changeInputHandle(event)}
            value={this.state.value}
          >
            {(inputProps: any) => (
              <input
                {...inputProps}
                className="FormInput"
                type={this.props.type}
                placeholder={this.props.placeholder}
                style={{
                  width: this.props.width,
                  height: this.props.height,
                  background: `url(/images/icons/${this.props.icon}) no-repeat scroll 15px 10px`,
                  backgroundPositionY: 'center',
                }}
              />
            )}
          </InputMask>
        ) : (
          <input
            onChange={(event) => this.changeInputHandle(event)}
            value={this.state.value}
            className="FormInput"
            type={this.props.type}
            placeholder={this.props.placeholder}
            style={{
              width: this.props.width,
              height: this.props.height,
              background: `url(/images/icons/${this.props.icon}) no-repeat scroll 15px 10px`,
              backgroundPositionY: 'center',
            }}
          />
        )}
      </React.Fragment>
    )
  }
}

export default FormInput
