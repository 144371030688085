import React from 'react'
import './Card.scss'
import {RootState} from "../../Redux";
import {connect} from "react-redux";
import {I18nState} from "react-redux-i18n";

interface CardProps{
    img: string
    onClick: () => void
    title?: string
    title_en?: string
    meatType?: string
    weight?: string
    i18n?: I18nState
}

const Card = (props: CardProps) => {

    return (
        <div onClick={() => props.onClick()} className={"Card"}>
            <div>
                <img className={"img-fluid"} src={`${props.img}`} alt={props.title}/>
            </div>
            <div>
                <div className={"Card__title"}>{props.i18n?.locale === "en" ? props.title_en: props.title}</div>
                <div className={"Card__meat-type"}>{props.meatType}</div>
                <div className={"Card__weight"}>{props.weight}</div>
            </div>

        </div>
)
}


const mapStateToProps = (state: RootState) => {
    const i18n = state.i18nState
    return {
        i18n,
    }
}


export default connect(mapStateToProps, null)(Card)