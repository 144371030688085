import React, {useState} from 'react'
import {Container} from 'react-bootstrap'
import './MainHeader.scss'
import LanguageController from "../../../SharedComponents/LanguageController/LanguageController";
import {Translate} from "react-redux-i18n";
import {
    Link
} from "react-router-dom";
import {disableBodyScroll, enableBodyScroll} from "../../../utils/utils";
import Burger from "../../../SharedComponents/Burger/Burger";
import SocialButtonsGroup from "../../../SharedComponents/SocialButtonsGroup/SocialButtonsGroup";
import {setModalWindowSearch} from "../../../Redux/actions/modal";
import {RootState} from "../../../Redux";
import {connect} from "react-redux";
import * as Scroll from 'react-scroll'

interface MainHeaderProps {
    setModalWindowSearch: (isActive: boolean) => void
}


const MainHeader = (props: MainHeaderProps) => {


    const [isShowBurger, setShowBurger] = useState(false)


    const hideBurger = () => {
        enableBodyScroll()
        setShowBurger(false)
    }
    const showBurger = () => {
        disableBodyScroll()
        setShowBurger(true)
    }

    const showModalSearch = () => {
        disableBodyScroll()
        props.setModalWindowSearch(true)
    }
    const scrollToElement: (element: string) => void = (element: string) => {
        Scroll.scroller.scrollTo(element, {
            duration: 500,
            delay: 0,
            smooth: true,
        })
    }


    return (
        <Container fluid className="MainHeader">
            <Container className="MainHeader__content">
                {isShowBurger ? <Burger hideBurger={() => hideBurger()}/> : null}
                <div className="MainHeader__buttons justify-content-end justify-content-sm-between">
                    <div className="MainHeader__buttons_left d-none d-sm-flex ">
                        <SocialButtonsGroup/>
                    </div>
                    <div className="MainHeader__buttons_right mr-3">
                        <Container fluid className="MainHeader__burger d-block d-sm-none"
                                   onClick={() => showBurger()}>

                            <img src={window.location.origin + "/icons/burger.svg"} alt="burger"/>
                        </Container>
                        <Container onClick={() => showModalSearch()} className={"MainHeader__search"} fluid>
                            <img src={window.location.origin + "/icons/search.svg"} alt="search"/>
                        </Container>
                        <LanguageController currentLocale={"ru"}/>

                    </div>
                </div>
                <div className="MainHeader__menu">
                    <div className="container MainHeader__menu-left">
                        <ul className={"d-none d-sm-flex"}>
                            <li><Link to="/catalog"><Translate value="menu.catalog"/></Link></li>
                            <li><Link to="/partners"><Translate value="menu.partners"/></Link></li>
                            <li>
                                <Link onClick={() => scrollToElement('About')} to="/">
                                    <Translate value="menu.about"/>
                                </Link>

                            </li>
                        </ul>
                    </div>
                    <div className="MainHeader__logo">
                        <Link to="/"><img src={window.location.origin + "/icons/logo.svg"} alt="logo.svg"/></Link>
                    </div>
                    <div className="container MainHeader__menu-right">
                        <ul className={"d-none d-sm-flex"}>
                            <li><Link onClick={() => scrollToElement('News')} to="/">
                                <Translate value="menu.news"/>
                            </Link></li>
                            <li><Link to="/recipes">
                                <Translate value="menu.recipes"/>
                            </Link></li>
                            <li><Link to="/contacts"><Translate value="menu.contacts"/></Link></li>
                        </ul>
                    </div>

                </div>

            </Container>
        </Container>
    )
}

const mapDispatchToProps = {
    setModalWindowSearch: setModalWindowSearch,

}
const mapStateToProps = (state: RootState) => {
    const modal = state.modal
    return {
        modal,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MainHeader)